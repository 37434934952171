import website from '../assets/web.jpg'
import instagram from '../assets/instagram.jpg'
import yt from '../assets/yt.jpg'
import facebook from '../assets/facebook.jpg'
import mail from '../assets/mail.jpg'
import linkedin from '../assets/linkedin.jpg'

const items = [{
    "title": "Webové stránky",
    "subtitle": "Všechny informace na jednom místě - oficiální web českého zastoupení",
    "image": website,
    "link": "https://pol-skone.cz"
},
{
    "title": "Facebook",
    "subtitle": "@polskonecz | Oficiální facebook českého zastoupení společnosti POL-SKONE",
    "image": facebook,
    "link": "https://fb.me/polskonecz"
},
{
    "title": "Instagram",
    "subtitle": "@polskone_cz | Jeden follow změní Vaše bydlení k nepoznání...",
    "image": instagram,
    "link": "https://instagram.com/polskone_cz"
},
{
    "title": "E-mail",
    "subtitle": "info@pol-skone.cz | Primární e-mailová adresa naší společnosti, napište nám!",
    "image": mail,
    "link": "mailto://info@pol-skone.cz"
},
{
    "title": "LinkedIn",
    "subtitle": "@pol-skonecz | Největší profesní sociální síť, navážeme spojení?",
    "image": linkedin,
    "link": "https://cz.linkedin.com/company/pol-skonecz"
},
{
    "title": "YouTube",
    "subtitle": "POL-SKONE CZ | Videa k našim produktům a mnohem více!",
    "image": yt,
    "link": "https://www.youtube.com/channel/UCXSnBztwytphedIVQ8Q753w"
}]

export default items
